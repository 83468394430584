
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import approveOperation from './approve-operation';
import approveTransaction from './approve-transaction';
import authentication from './authentication';
import closeUAC from './close-uac';
import documents from './documents';
import fetchDocumentPresignUrl from './fetch-document-presign-url';
import fetchEarnDocumentPresignUrl from './fetch-earn-document-presign-url';
import fetchEarnPresignUrl from './fetch-earn-presign-url';
import fetchIdentities from './fetch-identities';
import fetchIdentitiesCount from './fetch-identities-count';
import fetchIdentityById from './fetch-identity-by-id';
import fetchIdentityDocuments from './fetch-identity-documents';
import fetchIdentityOperations from './fetch-identity-operations';
import fetchIdentityVerifications from './fetch-identity-verifications';
import fetchOperation from './fetch-operation';
import fetchOperationDocuments from './fetch-operation-documents';
import fetchOperations from './fetch-operations';
import fetchPartner from './fetch-partner';
import fetchPartnerOperations from './fetch-partner-operations';
import fetchPartners from './fetch-partners';
import fetchPresignUrl from './fetch-presign-url';
import fetchTotalBalance from './fetch-total-balance';
import fetchTransactionById from './fetch-transaction-by-id';
import fetchTransactions from './fetch-transactions';
import fetchUacById from './fetch-uac-by-id';
import fetchUacs from './fetch-uacs';
import fetchUser from './fetch-user';
import fetchUserRoles from './fetch-user-roles';
import identities from './identities';
import insertOperationDocument from './insert-operation-document';
import logout from './logout';
import patchIdentity from './patch-identity';
import patchUser from './patch-user';
import postUserPassword from './post-user-password';
import rejectOperation from './reject-operation';
import reopenIdentityReview from './reopen-identity-review';
import reviewIdentity from './review-identity';
import signIn from './sign-in';
import uacs from './uacs';
import uploadIdentityDocument from './upload-identity-document';

/**
 * Export `reducer`.
 */

export default combineReducers({
  approveOperation,
  approveTransaction,
  authentication,
  closeUAC,
  documents,
  fetchDocumentPresignUrl,
  fetchEarnDocumentPresignUrl,
  fetchEarnPresignUrl,
  fetchIdentities,
  fetchIdentitiesCount,
  fetchIdentityById,
  fetchIdentityDocuments,
  fetchIdentityOperations,
  fetchIdentityVerifications,
  fetchOperation,
  fetchOperationDocuments,
  fetchOperations,
  fetchPartner,
  fetchPartnerOperations,
  fetchPartners,
  fetchPresignUrl,
  fetchTotalBalance,
  fetchTransactionById,
  fetchTransactions,
  fetchUacById,
  fetchUacs,
  fetchUser,
  fetchUserRoles,
  identities,
  insertOperationDocument,
  logout,
  patchIdentity,
  patchUser,
  postUserPassword,
  rejectOperation,
  reopenIdentityReview,
  reviewIdentity,
  signIn,
  uacs,
  uploadIdentityDocument
} as any);
