
/**
 * Module dependencies.
 */

import type { Transaction, VerificationStatus } from 'client/core/types/transactions';
import { approveTransaction } from 'client/core/redux/actions/approve-transaction';
import { fetchIdentityById, resetFetchIdentityById } from 'client/core/redux/actions/fetch-identity-by-id';
import { getApproveTransactionState } from 'client/core/redux/selectors/approve-transaction';
import { getFetchIdentityByIdState } from 'client/core/redux/selectors/fetch-identity-by-id';
import { ifProp } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import React, { useCallback, useEffect } from 'react';
import ReviewButtons from './review-buttons';
import TransactionInformation from './transaction-information';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isSelected: boolean;
  transaction: Transaction;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `TransactionDetails` component.
 */

function TransactionDetails({ isSelected, transaction }: Props): JSX.Element {
  const {
    amount,
    appId,
    createdAt,
    currency,
    destinationAddress,
    id,
    identityId,
    status,
    transactionDetails,
    type
  } = transaction;

  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const history = useHistory();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { isSuccessful } = useSelector(getApproveTransactionState);
  const { data: identity } = useSelector(getFetchIdentityByIdState);
  const handleReview = useCallback(async (order: VerificationStatus) => {
    switch (order) {
      case 'approved': {
        try {
          await dispatch(approveTransaction(appId, id));
        } catch (error) {
          showErrorMessage(translate('transactionDetails.errors.confirm'));
        }

        return;
      }

      default:
        return;
    }
  }, [appId, dispatch, id, showErrorMessage, translate]);

  const handleFetchTransactionById = useCallback(
    (identityId: string) => {
      return dispatch(fetchIdentityById(identityId));
    },
    [dispatch]
  );

  useEffect(() => {
    handleFetchTransactionById(identityId);

    return () => dispatch(resetFetchIdentityById());
  }, [handleFetchTransactionById, identityId, dispatch]);

  useEffect(() => {
    if (isSuccessful) {
      history.push({ pathname: routes.dashboard.transactions });
      showSuccessMessage(translate('transactionDetails.approveTransaction.successMessage'));
    }
  }, [dispatch, history, isSuccessful, showSuccessMessage, translate]);

  return (
    <Wrapper isSelected={isSelected}>
      <TransactionInformation
        amount={amount}
        createdAt={createdAt}
        currency={currency}
        destinationAddress={destinationAddress}
        id={id}
        identity={identity}
        identityId={identityId}
        status={status}
        transactionDetails={transactionDetails}
        type={type}
      />

      {status === 'review' && <ReviewButtons handleReview={handleReview} />}
    </Wrapper>
  );
}

/**
 * Export `TransactionDetails` component.
 */

export default TransactionDetails;
