/**
 * Module dependencies.
 */

import axios from 'axios';

/**
 * `UploadDocument` type.
 */

type UploadDocument = {
  fields: Record<string, any>;
  file: File;
  url: string;
};

/**
 * Export `uploadDocument`.
 */

export function uploadDocument(options: UploadDocument) {
  const { fields, file, url } = options;
  const formData = new FormData();

  Object.entries(fields).map(([key, value]) => {
    formData.append(key, value);
  });

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  return axios.post(url, formData);
}
