/**
 * Module dependencies.
 */

import { Dispatch } from 'redux';
import { fetchIdentityDocuments } from 'client/core/redux/actions/fetch-identity-documents';
import { fetchPresignUrl } from 'client/core/redux/actions/fetch-presign-url';
import { uploadDocument } from 'client/core/utils/upload-document';
import { uploadIdentityDocument } from 'client/core/redux/actions/upload-identity-document';

/**
 * Export `IdentityDocumentUpload` type.
 */

export type IdentityDocumentUpload = {
  document: File;
  documentSide: string;
  documentType: string;
  identityId: string;
};

/**
 * Export `performIdentityDocumentUpload`.
 */

export const performIdentityDocumentUpload = (values: IdentityDocumentUpload) => async (dispatch: Dispatch) => {
  const { document: file, documentSide, documentType, identityId } = values;

  const { name: fileName } = file;
  const {
    value: { data }
  } = await dispatch(fetchPresignUrl({ fileName, identityId }));
  const {
    fields: { key },
    url
  } = data;
  const fileUrl = `${url}${key}`;

  await uploadDocument({ ...data, file });
  await dispatch(
    uploadIdentityDocument({
      identityId,
      label: fileName,
      side: documentSide,
      type: documentType,
      url: fileUrl
    })
  );

  await dispatch(fetchIdentityDocuments(identityId));
};
